<template>
  <v-layout class="mb-4 pb-4" wrap>
    <v-flex xs12>
      <AppTitle title="Links de intereses" subtitle="" />
    </v-flex>
    <v-flex xs12>
      <v-card>
        <v-data-table
          item-key="id"
          :headers="headers"
          :items="items"
          :loading="loading"
          rows-per-page-text="Pedidos por pagina"
          no-data-text="No hay links ingresados"
          class="elevation-0"
          hide-actions
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td>
                {{ props.item.name }}
              </td>
              <td>
                <a :href="props.item.url_target" target="_blank">
                  {{ props.item.url_target }}
                </a>
              </td>
              <td>
                {{ props.item.title }}
              </td>
              <td>
                <v-icon small class="mr-2" @click="editItem(props.item)">
                  edit
                </v-icon>
                <v-icon small class="mr-2" @click="deleteItem(props.item.id)">
                  delete
                </v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
      <v-btn fab dark fixed bottom right color="success" @click="newItem">
        <v-icon>add</v-icon>
      </v-btn>
    </v-flex>
    <v-dialog v-model="dialog" width="800" persistent>
      <v-card>
        <AppTitleDialog
          :title="'Agregar link'"
          :close-action="
            () => {
              dialog = false
            }
          "
          close
        />
        <v-card-text class="pa-0">
          <v-container grid-list-lg>
            <v-layout wrap>
              <v-flex xs6>
                <v-text-field v-model="form.name" hide-details label="Palabra" />
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-model="form.url"
                  label="Url"
                  hint="Ej: /flowerbox o https://www.rosalinda.cl/flowerbox"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field v-model="form.title" hide-details label="Titulo del link" />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn flat color="back" depressed @click="dialog = false">
            Volver
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn flat color="accent" depressed @click="save"> Listo </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { CONFIG } from '../../config'
import AppTitle from '../useful/title.vue'
import AppTitleDialog from '../useful/titleDialog.vue'

export default {
  name: 'ViewPage',
  components: {
    AppTitle,
    AppTitleDialog
  },
  data() {
    return {
      loading: false,
      items: [],
      categories: [],
      tags: [],
      sections: [],
      dialog: false,
      form: {
        name: '',
        type: '',
        slug: '',
        list: '',
        status: true,
        category_id: 0,
        url: '',
        description: '',
        section_id: 0
      },
      headers: [
        {
          text: 'Palabra',
          align: 'left',
          sortable: false
        },
        {
          text: 'Url',
          align: 'left',
          sortable: false
        },
        {
          text: 'Title',
          align: 'left',
          sortable: false
        }
      ]
    }
  },
  watch: {
    'form.namePublic': function productName() {
      this.form.slug = this.toSeoUrl(this.form.namePublic)
    }
  },
  mounted() {
    this.getLinks()
  },
  methods: {
    async getLinks() {
      try {
        const res = await this.$http.get(`${CONFIG}/links`)
        this.items = res.data
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async save() {
      try {
        await this.$http({
          method: this.form.id ? 'put' : 'post',
          url: this.form.id ? `${CONFIG}/links/${this.form.id}` : `${CONFIG}/links`,
          data: {
            form: this.form
          }
        })
        this.dialog = false
        this.getLinks()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async deleteItem(id) {
      try {
        await this.$http.delete(`${CONFIG}/links/${id}`)
        this.getLinks()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    newItem() {
      this.dialog = !this.dialog
      this.form = {
        name: '',
        type: '',
        slug: '',
        list: '',
        url: '',
        description: '',
        status: true,
        category_id: 0,
        section_id: 0
      }
    },
    editItem(item) {
      this.form = item
      this.dialog = !this.dialog
    },
    toSeoUrl(url) {
      return (
        url
          .toString()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/\s+/g, '-')
          .toLowerCase()
          .replace(/&/g, '-and-')
          // eslint-disable-next-line no-useless-escape
          .replace(/[^a-z0-9\-]/g, '')
          .replace(/-+/g, '-')
          .replace(/^-*/, '')
          .replace(/-*$/, '')
      )
    }
  }
}
</script>
